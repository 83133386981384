export function dataInJs(e) {
	if(e=='cn'){
		var rows = [{
			name:'IC采购经理',
			schooling:'本科',
			time:'2023-03-21',
			pay:'面议',
			workingHours:['1、朝九晚六，中午一个半小时休息（7.5小时）；','2、每周工作五天，不加班；','3、按国家法定节假日安排，休假；','4、入职即享有最多5天以上的带薪年休假；','5、春节提前数天放假；'],
			materialBenefits:['1、劳动报酬：底薪+绩效+提成+年终奖 ，单月提成***记录9W，年终奖***记录50万，年综合收益100万的记录等您来打破；','2，社保：保障您的医疗和养老；','3、公司旅游：不定期组织户外活动或旅游；','4、丰富的假期：病假、婚假、丧假、哺乳假、产假、年假等有薪假期；','5、学习成长：良好的思维模型培训，让您终生受益；','6、其它的福利：表现优秀的欢迎来找人事谈想要的福利。'],
			askList:['1、从事电子元器件贸易行业5年以上；','2，有主动元件及被动元件的采购经验；','3、计算机操作熟练，英语4级以上；','4、具有良好的沟通能力；','5、逻辑思维清晰；','6、能承受压力,拥有强烈目标管理和执行能力；','7、有很好的客户服务意识；','8、熟悉各个渠道供应商的情况，能很好的整合优化供应商；','9、了解产品的分类 和特点以及订购时候需要注意的事项；'],
			jobContentList:['1、能统筹采购部的工作，做好公司采购管理；','2、分析电子元器件市场变化，为公司销售提供市场咨询支持；','3、根据销售部的订单，进行IC电子元器件询价、采购谈判并采购相关的货物；','4、追踪所负责的采购订单的执行，追踪货物物流动向；','5、按照公司的采购流程，完成公司下达的采购任务，合理控制成本；','6、通过各种媒介、平台开发全球潜在IC供应商，管理供应商系统及拓展供应商关系，为公司找寻优秀供应商；','7、与供应商进行商业洽谈，维护供应商的关系，为进一步的成本控制打下基础；','8、在工作上善于乐于沟通，乐于配合；','9、完成领导安排的其它工作。']
		},
		{
			name:'IC销售工程师',
			schooling:'专科',
			time:'2023-03-21',
			pay:'面议',
			workingHours:['1、朝九晚六，中午一个半小时休息（7.5小时）；','2、每周工作五天，不加班；','3、按国家法定节假日安排，休假；','4、入职即享有最多5天以上的带薪年休假；','5、春节提前数天放假；'],
			materialBenefits:['1、劳动报酬：底薪+绩效+提成+年终奖 ，单月提成***记录9W，年终奖***记录50万，年综合收益100万的记录等您来打破；','2，社保：保障您的医疗和养老；','3、公司旅游：不定期组织户外活动或旅游；','4、丰富的假期：病假、婚假、丧假、哺乳假、产假、年假等有薪假期；','5、学习成长：良好的思维模型培训，让您终生受益；','6、其它的福利：表现优秀的欢迎来找人事谈想要的福利。'],
			askList:['1、大专以上学历；','2、立志并热爱销售工作；','3、计算机操作熟练；','4、具有良好的沟通能力；','5、逻辑思维清晰；','6、能承受压力,拥有强烈目标管理和执行能力；','7、有很好的客户服务意识；','8、能接受短期的出差；'],
			jobContentList:['1、通过电话，网络、拜访等方式开拓国内电子元器件贸易商、终端商、工厂等客户；','2、收集市场和行业信息，不断学习以满足销售目标及客户需求;','3、向客户提供需要的产品以及相关的市场信息；','4、完成销售任务、及时收回货款；','5、处理好ERP业务流程；','6、与公司各部门配合，及时处理跟进客户需求，维护客户关系；','7、领导安排的其它工作。']
		},
		{
			name:'IC采购专员',
			schooling:'专科',
			time:'2023-03-21',
			pay:'面议',
			workingHours:['1、朝九晚六，中午一个半小时休息（7.5小时）；','2、每周工作五天，不加班；','3、按国家法定节假日安排，休假；','4、入职即享有最多5天以上的带薪年休假；','5、春节提前数天放假；'],
			materialBenefits:['1、劳动报酬：底薪+绩效+提成+年终奖 ，单月提成***记录9W，年终奖***记录50万，年综合收益100万的记录等您来打破；','2，社保：保障您的医疗和养老；','3、公司旅游：不定期组织户外活动或旅游；','4、丰富的假期：病假、婚假、丧假、哺乳假、产假、年假等有薪假期；','5、学习成长：良好的思维模型培训，让您终生受益；','6、其它的福利：表现优秀的欢迎来找人事谈想要的福利。'],
			askList:['1、从事电子元器件贸易行业1-2年以上，并有原厂和代理渠道者优先；','2，有主动元件及被动元件的采购经验；','3、计算机操作熟练，英语4级以上；','4、具有良好的沟通能力；','5、逻辑思维清晰；','6、能承受压力,拥有强烈目标管理和执行能力；','7、有很好的客户服务意识；','8、熟悉各个渠道供应商的情况；','9、了解产品的分类 和特点以及订购时候需要注意的事项；'],
			jobContentList:['1、分析电子元器件市场变化，为公司销售提供市场咨询支持；','2、根据销售部的订单，进行IC电子元器件询价、采购谈判并采购相关的货物；','3、追踪所负责的采购订单的执行，追踪货物物流动向；','4、按照公司的采购流程，完成公司下达的采购任务，合理控制成本；','5、通过各种媒介、平台开发全球潜在IC供应商，管理供应商系统及拓展供应商关系，为公司找寻优秀供应商；','6、与供应商进行商业洽谈，维护供应商的关系，为进一步的成本控制打下基础；','7、在工作上善于乐于沟通，乐于配合；','8、完成领导安排的其它工作。']
		}];
	}else{
		var rows = [{
			name:'IC Purchasing Manager',
			schooling:'undergraduate course',
			time:'2023-03-21',
			pay:'discuss personally',
			workingHours:['1、Morning nine to six, noon one and a half hour rest (7.5 hours)；','2. Work five days a week without overtime；','3. Holidays are arranged according to national statutory holidays；','4、Enjoy a maximum of 5 days of paid annual leave upon entry；','5、The Spring Festival holiday comes a few days ahead of schedule；'],
			materialBenefits:['1. Labor remuneration: base salary+performance+commission+year-end bonus, single month commission * * * record of 9W, year-end bonus * * * record of 500000, and annual comprehensive income of 1000000 are waiting for you to break the record；','2，Social security: ensuring your medical care and retirement；','3、Corporate tourism: irregularly organize outdoor activities or tours；','4、Rich holidays: Paid holidays such as sick leave, marriage leave, funeral leave, breastfeeding leave, maternity leave, annual leave, etc；','5、Learning and growth: good thinking model training that benefits you throughout your life；','6、Other benefits: Those who perform well are welcome to talk to personnel about the benefits they want。'],
			askList:['1、Engaged in electronic component trade industry for more than 5 years；','2，Experience in purchasing active and passive components；','3、Proficient in computer operation, English level 4 or above；','4、Good communication skills；','5、Clear logical thinking；','6、Able to withstand pressure, strong ability to manage and execute goals；','7、Have a good sense of customer service；','8、Familiar with the situation of various channel suppliers, able to integrate and optimize suppliers well；','9、Understand the classification and characteristics of products and precautions when ordering；'],
			jobContentList:['1、Be able to coordinate the work of the procurement department and do a good job in company procurement management；','2、Analyze market changes in electronic components and provide market consulting support for company sales；','3、Conduct inquiry, procurement negotiation, and purchase related goods for IC electronic components based on orders from the sales department；','4、Track the execution of responsible purchase orders and track the logistics trends of goods；','5、Complete the procurement tasks assigned by the company according to the company procurement process, and reasonably control costs；','6、Develop potential global IC suppliers through various media and platforms, manage supplier systems, and expand supplier relationships to find excellent suppliers for the company；','7、Conduct business negotiations with suppliers, maintain supplier relationships, and lay the foundation for further cost control；','8. Good at communication and cooperation in work；','9、Complete other tasks assigned by the leader。']
		},
		{
			name:'IC Sales Engineer',
			schooling:'junior college',
			time:'2023-03-21',
			pay:'discuss personally',
			workingHours:['1、Morning nine to six, noon one and a half hour rest (7.5 hours)；','2、Work five days a week without overtime；','3、Holidays are arranged according to national statutory holidays；','4、Enjoy a maximum of 5 days of paid annual leave upon entry；','5、The Spring Festival holiday comes a few days ahead of schedule；'],
			materialBenefits:['1、Labor remuneration: base salary+performance+commission+year-end bonus, single month commission * * * record of 9W, year-end bonus * * * record of 500000, and annual comprehensive income of 1000000 are waiting for you to break the record；','2、Social security: ensuring your medical care and retirement；','3、Corporate tourism: irregularly organize outdoor activities or tours；','4、Rich holidays: Paid holidays such as sick leave, marriage leave, funeral leave, breastfeeding leave, maternity leave, annual leave, etc；','5、Learning and growth: good thinking model training that benefits you throughout your life；','6、Other benefits: Those who perform well are welcome to talk to personnel about the benefits they want。'],
			askList:['1、College Degree；','2、Determined and passionate about sales；','3、Proficient in computer operation；','4、Good communication skills；','5、Clear logical thinking；','6、Able to withstand pressure, strong ability to manage and execute goals；','7、Have a good sense of customer service；','8、Able to accept short-term business trips；'],
			jobContentList:['1、Expand domestic electronic component traders, terminal merchants, factories, and other customers through telephone, internet, and visits；','2、Collect market and industry information and continuously learn to meet sales goals and customer needs;','3、Provide customers with required products and relevant market information；','4、Complete sales tasks and timely collect payment for goods；','5、Handle ERP business processes well；','6、Cooperate with various departments of the company, timely handle and follow up customer needs, and maintain customer relationships；','7、Other work arranged by the leader。']
		},
		{
			name:'IC Procurement Specialist',
			schooling:'junior college',
			time:'2023-03-21',
			pay:'discuss personally',
			workingHours:['1、Morning nine to six, noon one and a half hour rest (7.5 hours)；','2、Work five days a week without overtime；','3、Holidays are arranged according to national statutory holidays；','4、Enjoy a maximum of 5 days of paid annual leave upon entry；','5、The Spring Festival holiday comes a few days ahead of schedule；'],
			materialBenefits:['1、Labor remuneration: base salary+performance+commission+year-end bonus, single month commission * * * record of 9W, year-end bonus * * * record of 500000, and annual comprehensive income of 1000000 are waiting for you to break the record；','2，Social security: ensuring your medical care and retirement；','3、Corporate tourism: irregularly organize outdoor activities or tours；','4、Rich holidays: Paid holidays such as sick leave, marriage leave, funeral leave, breastfeeding leave, maternity leave, annual leave, etc；','5、Learning and growth: good thinking model training that benefits you throughout your life；','6、Other benefits: Those who perform well are welcome to talk to personnel about the benefits they want。'],
			askList:['1、Engaged in electronic component trading industry for more than 1-2 years, with original factory and agency channels is preferred；','2，Experience in purchasing active and passive components；','3、Proficient in computer operation, English level 4 or above；','4、Good communication skills；','5、Clear logical thinking；','6、Able to withstand pressure, strong ability to manage and execute goals；','7、Have a good sense of customer service；','8、Familiar with the situation of various channel suppliers；','9、Understand the classification and characteristics of products and precautions when ordering；'],
			jobContentList:['1、Analyze market changes in electronic components and provide market consulting support for company sales；','2、Conduct inquiry, procurement negotiation, and purchase related goods for IC electronic components based on orders from the sales department；','3、Track the execution of responsible purchase orders and track the logistics trends of goods；','4、Complete the procurement tasks assigned by the company according to the company procurement process, and reasonably control costs；','5、Develop potential global IC suppliers through various media and platforms, manage supplier systems, and expand supplier relationships to find excellent suppliers for the company；','6、Conduct business negotiations with suppliers, maintain supplier relationships, and lay the foundation for further cost control；','7、Good at communication and cooperation in work；','8、Complete other tasks assigned by the leader。']
		}];
	}
	return rows;
}
